
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        





























































































































































































































































































































































































.is-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat-button {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: none;
    padding: 0;
    position: relative;
    display: flex;
    align-items: center;

    &:hover {
        background-color: $light-light;
    }

    ::v-deep {
        span {
            height: max-content;
            display: flex;
            align-items: center;
        }
    }

    .alert {
        width: 8px;
        height: 8px;
        position: absolute;
        border-radius: 8px;
        background-color: $info;
        top: 4px;
        right: 4px;
    }
}

::v-deep {
    .table {
        .header-date {
            min-width: 160px;

            .nowrap {
                white-space: normal !important;
            }
        }

        th {
            vertical-align: middle;
        }
    }
}
