
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        



























































































.modal {
    ::v-deep {
        .modal-card {
            width: 97vw;
            max-width: 97vw;
        }

        .b-skeleton {
            margin-left: 0.5rem;
            width: 80px;
        }
    }
}
