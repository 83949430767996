
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        


















































































































































































.color-wrapper {
    width: 30px;
    height: 30px;
    border-radius: 10px;

    ::v-deep {
        span {
            display: flex;
            align-items: center;
        }

        svg {
            width: 16px !important;
            height: 16px !important;
        }
    }
}
