
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        



































































































::v-deep {
    tr.is-empty {
        td {
            text-align: center;
        }
    }

    .b-skeleton {
        width: auto;
        margin: 0 !important;
    }
}

.context-wrapper {
    background-color: #FAFAFA;
    border-radius: 1rem;
    margin: 12px;
    font-size: .85rem;

    ::v-deep {
        .columns {
            padding: 0 8px;
        }
        .column {
            font-weight: bold;
            padding: 4px 8px;
        }
        .tag {
            margin-left: 4px;
        }
    }
}
