
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

































































































































.tags {
  margin-bottom: 0.1rem !important;
}
.is-disabled {
  cursor: not-allowed;
}
::v-deep {
  .tag {
    padding: 1.1rem 0.8rem;
    border-radius: 20px;

    span {
      display: flex;
      align-content: center;

      font-size: .85rem;

      img {
        $size: 20px;

        width: $size;
        height: $size;
      }
    }
  }
}
