
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        






































































































































































$color-primary: #fbf1df;
$color-wrapper: #efefef;
$color-bg: #fafafa;
$color-shadow: #81818140;

.chart {
    $self: &;

    &-wrapper {
        border: 1px solid $color-wrapper;
        border-radius: .5rem;
        height: 50vh;
        overflow: hidden;
        overflow-y: auto;
        padding: .5rem 1rem;
        background: $color-bg;
        word-break: break-word;

        .text {
            white-space: pre-wrap;
        }

        @include touch {
            height: 50vh;
        }

        @include mobile {
            height: 40vh;
        }

        .is-flex-direction-row-reverse {
            #{ $self }-triangle {
                transform: scale(-1, 1);
                left: auto;
                right: -11px;
                color: $color-primary;
            }

            #{ $self }-item {
                border-bottom-left-radius: 1rem;
                border-bottom-right-radius: 0;
                background-color: $color-primary;
            }
        }
    }

    &-triangle {
        position: absolute;
        bottom: -7px;
        left: -12px;
        text-shadow: 0 0 4px 0 $color-shadow;
        color: $color-bg;

        &.is-flex-direction-row-reverse {
            text-shadow: none;
        }
    }

    &-item {
        width: max-content;
        padding: .3rem .6rem;
        border: 1px solid $light-light;
        box-shadow: 0 0 10px rgba(172, 172, 172, 0.25);
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }
}

::v-deep {
    .textarea {
        height: 56px;
        min-height: 56px;
        max-height: 170px !important;
        resize: none;
        font-size: 1rem;
        padding: 1rem 4rem 1rem 1rem;

        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        border-color: $color-wrapper;

        &:focus {
            box-shadow: none;
        }

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    .has-text-centered {
        .textarea {
            text-align: center;
        }
    }

    .is-expanded {
        width: 100%;
    }

    .help {
        position: absolute;
        right: 0;
        color: $light;
    }
}

.send-button {
    position: absolute;
    bottom: 50%;
    right: 1rem;
    font-size: 1rem;
    transform: translateY(50%);

    &:disabled:hover {
        color: $light;
    }

    ::v-deep {
        i {
            margin: 0.25rem;
        }
    }
}

.input-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 30px;
}
