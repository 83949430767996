
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        


























































































































































































































.one-line {
    word-break: break-word;
    white-space: nowrap;
}

.link {
    color: $link;
    cursor: pointer;
}
.multiline {
    word-break: break-word;
}
.text {
  white-space: pre-wrap;
}
