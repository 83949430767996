
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        










































































































































































































































::v-deep {
    .modal-card {
        width: 80vh;
    }

    .field.has-addons {
        justify-content: space-between;
    }
}

.color-wrapper {
    width: 36px;
    height: 36px;

    ::v-deep {
        span {
            display: flex;
            align-items: center;
        }
    }
}

.disabled {
    cursor: not-allowed !important;
    opacity: 0.6;
}

.title.history {
    font-size: 18px;
    margin-bottom: 20px;
}
